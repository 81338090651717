<div class="divider"></div>
<p>Lišácké předplatné pro vás pražíme v <a href="https://projectfox.cz">Project FOX</a>.</p>
<section>
  <ul class="strong">
    <li><a routerLink="/jak-to-funguje">Jak to funguje?</a></li>
    <li><a routerLink="/pobocky">Pobočky</a></li>
    <li><a routerLink="/jak-to-funguje" fragment="casto-kladene-dotazy">Často kladené dotazy</a></li>
    <li><a routerLink="/aktivovat">Aktivovat plastovou kartu</a></li>
    <li><a routerLink="/voucher">Uplatnit voucher</a></li>
  </ul>
  <ul>
    <li><a routerLink="/kontakt">Kontakt</a></li>
    <li><a routerLink="/podminky">Podmínky</a></li>
    <li><a routerLink="/soukromi">Soukromí</a></li>
  </ul>
</section>
